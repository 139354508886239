<template>
  <v-form
    class="rcc-suppliers-form"
    ref="form"
    @submit.prevent="handleFormSubmit"
    v-model="valid"
  >
    <div class="rcc-suppliers-form__form-body">
      <div v-if="isAdmin" class="rcc-suppliers-form__form-body-column">
        <rcc-text-input
          label="Название"
          v-model="form.name"
          :rules="[value => isRequiredField({ value, field: 'Название' })]"
          width="500px"
        />

        <rcc-textarea v-model="form.comment" label="Комментарий" width="500px" />

        <rcc-select
          v-model="form.contract_type"
          label="Тип контракта"
          :items="contractTypeSelectOptions"
          :rules="[value => isRequiredField({ value, errorText: 'Выберите тип контракта' })]"
          width="500px"
        />

        <section class="supplier-statuses">
          <h2>Статусы заявок к отображению в ЛК</h2>

          <div class="statuses-groups">
            <div
              v-for="(group, index) in returnStatusesByGroups"
              :key="index"
              class="status-group"
            >
              <div class="status-group-name">
                {{ group.groupName }}
              </div>

              <rcc-checkboxes-group
                v-model="form.return_status_ids"
                choose-by="id"
                show-by="name"
                :options="group.statuses"
              />
            </div>
          </div>
        </section>

        <div class="rcc-suppliers-form__auth-section">
          <div class="rcc-suppliers-form__auth-wrapper">
            <div class="rcc-suppliers-form__auth-input-wrapper">
              <label>Dialog</label>
            </div>
          </div>

          <div class="rcc-suppliers-form__auth-wrapper">
            <div class="rcc-suppliers-form__auth-input-wrapper">
              <rcc-text-input
                type="text"
                label="Логин на портале X5"
                v-model="form.x5_login"
                width="500px"
                @focus="handleLogPassFocus('x5_login')"
                @blur="handleLogPassBlur('x5_login')"
              />
            </div>
          </div>

          <div class="rcc-suppliers-form__auth-wrapper">
            <div class="rcc-suppliers-form__auth-input-wrapper">
              <rcc-text-input
                label="Пароль на портале X5"
                type="text"
                v-model="form.x5_pass"
                width="500px"
                @focus="handleLogPassFocus('x5_pass')"
                @blur="handleLogPassBlur('x5_pass')"
              />
            </div>
          </div>

          <div class="portal-auth-data-bottom-section">
            <rcc-checkbox
              v-if="isAdmin"
              label="Скрыть логин и пароль"
              v-model="form.secret_access"
              name="secret_access"
            />

            <!-- <rcc-button
              v-if="!form.secret_access"
              class="go-to-x5-portal-button"
              @click="goToX5Portal"
            >
              Перейти на портал  X5
            </rcc-button> -->
          </div>
        </div>
      </div>

      <section v-if="isAdmin">
        <h2>
          Торговые сети
        </h2>

        <rcc-checkboxes-group
          v-model="form.network_ids"
          choose-by="id"
          show-by="name"
          :options="tradingNetworks"
          class="checkboxes-grid"
        />
      </section>

      <rcc-return-points-list
        :networks="selectedTradingsNetworks"
        :selected-return-points.sync="form.werk_ids"
      />
    </div>

    <rcc-message-dialog
      title="Комментарий к смене статуса"
      saveText="Сменить статус"
      :is-show.sync="isShowDialog"
      :saveCallback="changeStatus"
      :placeholder="`Не более ${maxMessageLength} символов`"
      :rules="
        [
          val => val !== '' || `Не может быть пустым`,
          val => val.length <= maxMessageLength || `Комментарий должен быть не более ${maxMessageLength} символов`
        ]
      "
    />

    <rcc-footer-buttons
      :waningText="warningButtonText || ''"
      :is-submit="isSubmit"
      @warning-click="handleBlockSupplierClick"
      @cancel-click="() => cancelForm({ routeName: 'suppliers'})"
    />
  </v-form>
</template>

<script>
import { objectToOptions } from '@/utils/get-options'
import { required } from 'vuelidate/lib/validators'
import Form from '@/mixins/form'

import Page from '@/mixins/page'
import RccTextInput from 'Components/controls/rcc-text-input'
import RccSelect from 'Components/controls/rcc-select'
import RccTextarea from 'Components/controls/rcc-textarea'
import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccCheckboxesGroup from 'Components/controls/rcc-checkboxes-group'
import RccCheckbox from 'Components/controls/rcc-checkbox'
import RccMessageDialog from 'Components/dialogs/rcc-message-dialog'
import RccReturnPointsList from './return-points-list'
// import RccButton from 'Components/controls/rcc-button'

const SECRET_ACCESS_PLACEHOLDER = '********'
const X5_PORTAL_URL = 'https://dialog-sso.x5.ru/auth/realms/dialog/protocol/openid-connect/auth?client_id=bddl-public&redirect_uri=https%3A%2F%2Fdialog.x5.ru%2Fpartner&state=fcdc94a1-2d9b-4242-904f-60d386ddef34&response_mode=fragment&response_type=code&scope=openid&nonce=f9fee4ad-b277-46a5-b3f1-283bb33dfb59'

export default {
  components: {
    RccTextInput,
    RccSelect,
    RccTextarea,
    RccFooterButtons,
    RccCheckboxesGroup,
    RccCheckbox,
    RccMessageDialog,
    RccReturnPointsList
    // RccButton
  },

  mixins: [Page, Form],

  data() {
    return {
      isNew: false,
      isShowDialog: false,
      maxMessageLength: 300,
      dcsOptions: [],
      returnStatusesList: [],
      tradingNetworks: [],
      secretAccessPlaceholder: SECRET_ACCESS_PLACEHOLDER,
      initialSecretAccess: false,

      form: {
        secret_access: false,
        name: null,
        contract_type: null,
        x5_login: null,
        x5_pass: null,
        priority: null,
        comment: null,
        x5_second_login: null,
        x5_second_pass: null,
        werk_ids: [],
        return_status_ids: [],
        network_ids: []
      }
    }
  },

  validations: {
    form: {
      name: { required },
      contract_type: { required }
    }
  },

  computed: {
    contractTypeSelectOptions() {
      return objectToOptions({ object: this.$constants.contract })
    },

    returnStatusesByGroups() {
      const statusesByGroup =  this.returnStatusesList
        .reduce((acc, status) => {
          return { ...acc , [status.group]: [...(acc[status.group] || []), status] }
        }, {})

      return [
        this.$constants.statusesGroups.agreed,
        this.$constants.statusesGroups.processed,
        this.$constants.statusesGroups.others
      ]
        .map(group => (
          { groupName: this.$constants.statusesGroupsNames[group], statuses: statusesByGroup[group] || [] }
        ))
    },

    selectedTradingsNetworks() {
      return this.tradingNetworks.filter(network => this.form.network_ids.includes(network.id))
    },

    isAdmin() {
      return this.$store.user.role === this.$constants.users.admin
    },

    isManager() {
      return this.$store.user.role === this.$constants.users.manager
    }
  },

  created() {
    Promise.all([
      this.$apiMethods.tradingNetworks.allList(),
      this.$apiMethods.returnStatuses.allList()
    ]).then(([tradingNetworks, returnStatuses]) => {
      this.tradingNetworks = tradingNetworks.items
      this.setReturnStatuses(returnStatuses)
    })
  },

  methods: {
    goToX5Portal() {
      window.open(X5_PORTAL_URL, '_blank')
    },

    handleLogPassFocus(field) {
      if (this.initialSecretAccess && this.form[field] === this.secretAccessPlaceholder) {
        this.form[field] = ''
      }
    },

    handleLogPassBlur(field) {
      if (this.initialSecretAccess && this.form[field] === '') {
        this.form[field] = this.secretAccessPlaceholder
      }
    },

    handleBlockSupplierClick() {
      return
    },

    changeSupplierStatus() {
      return
    },

    changeStatus() {
      return
    },

    setReturnStatuses(items) {
      this.returnStatusesList = items
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-suppliers-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form-body{
    flex-grow: 1;
    padding: $base-form-padding;
  }

  &__auth-wrapper {
    display: flex;
    gap: 20px;
  }

  &__auth-input-wrapper {
    width: 50%;
    position: relative;

    label {
      display: inline-block;
      color: #5f6972;
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 800;
    }
  }
}
</style>

<style lang="scss">
.rcc-suppliers-form {
  .statuses-groups {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
  }

  .status-group-name {
    font-weight: 800;
    margin-bottom: 5px;
  }

  .checkboxes-grid {
    margin-top: 20px;
    width: 90%;

    & > div {
      width: 33%;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;

      & > div {
        width: 50%;
      }
    }

    @media screen and (max-width: 650px) {
      & > div {
        width: 100%;
      }
    }
  }

  .portal-auth-data-bottom-section {
    display: flex;
    align-items: center;
    gap: 30px;
    min-height: 30px;
  }
}
</style>
